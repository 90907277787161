@import url('https://fonts.googleapis.com/css2?family=Instrument+Sans:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400&display=swap');


@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --black-gradient: linear-gradient(
    144.39deg,
    #ffffff -278.56%,
    #6d6d6d -78.47%,
    #11101d 91.61%
  );
  --card-shadow: 0px 20px 100px -10px rgba(66, 71, 91, 0.1);
}

body, html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

ol {
  list-style: decimal;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

.footer {
  background-image: url('./assets/footerImg.jpg');
  object-fit: cover;
  width: auto;
}

.aboutMain {
  background-image: url('./assets/aboutMain.jpg');
  object-fit: cover;
  width: auto;
}

.show:hover button {
  opacity: 1;
}

@media screen and (max-width: 480px) {
  .mobile {
    height: 600px;
  }
}

@media screen and (max-width: 768px) {
  .mobjor {
    overflow-y: auto;
  }
}

@media (max-width: 480px) {
  .grid-cols-2 > div {
    grid-column: span 2;
  }
}

.navsmooth {
  transition: all 0.2s ease-in-out;
}

.box-shadow {
  box-shadow: 0px 20px 100px -10px rgba(66, 71, 91, 0.1);
}

.sidebar {
  -webkit-animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.hash-span {
  margin-top: -70px;
  padding-bottom: 70px;
  display: block;
}

.grow {
  transition: all .2s ease-in-out;
}
.grow:hover {
  transform: scale(0.98);
  background-color: #052e3b;
}

.grow2 {
  transition: all .2s ease-in-out;
}
.grow2:hover {
  transform: scale(0.98);
}

.grow3 {
  transition: all .2s ease-in-out;
}
.grow3:hover {
  transform: scale(1.05);
}

.grow4 {
  transition: all .2s ease-in-out;
}
.grow4:hover {
  transform: scale(1.01);
}

.grow5 {
  transition: all .2s ease-in-out;
}
.grow5:hover {
  transform: scale(1.01);
}

/* @media (max-width: 900px) {
  .aboutimage {
    align-items: center;
    justify-content: center;
  }  
} */

.collapsed {
  max-height: 50px;
  overflow: hidden;
  transition: max-height 0.3s ease-out;
}

.expanded {
  max-height: 1000px; 
  transition: max-height 0.3s ease-in;
}

.menu-slide-enter {
  transform: translateX(100%);
}

.menu-slide-enter-active {
  transform: translateX(0);
  transition: transform 0.4s ease-in-out;
}

.menu-slide-exit {
  transform: translateX(0);
}

.menu-slide-exit-active {
  transform: translateX(100%);
  transition: transform 0.4s ease-in-out;
}